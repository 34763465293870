import { createApp } from 'vue'
import App from './App.vue' //导入组件App
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { ElMessageBox } from 'element-plus';
// 引入 Element-UI Plus 中的图标组件及其样式
// import { ElIcon } from 'element-plus';
// import 'element-plus/icons'; // 或者根据实际路径引入特定的图标组件库
// import 'element-plus/theme-chalk/icon.css'; // 如果图标有独立样式，需要引入

createApp(App).use(store).use(router).use(ElementPlus).use(ElMessageBox).mount('#app')
