import { createStore } from 'vuex'

export default createStore({
  state: {
    // 申报列表
    reportList: [
    ],
    outUserName: "",
    ofUserName: "",
    outisLogin: Boolean,
    ofdata: {},
  },
  getters: {
  },
  mutations: {
    // setReportList(state, reportList) {
    //   state.reportList = state.reportList
    // },
  },
  actions: {
  },
  modules: {
  }
})
