<template>
<div>
  
  <router-view/> 
</div>

</template>

<script>
export default {
  data(){
  },
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
}
</style>

