import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {  
    path: '/',  
    redirect: '/first' // 当访问 '/' 时，重定向到 '/home'  
  }, 
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    children: [ 
    // 子路由1 
    { path: '/first', component: () => import('../views/first.vue'), }, 
    // 子路由2
    { path: '/object', component: () => import('../views/object.vue'), },
    { path: '/object2', component: () => import('../views/object2.vue'), }, 
    { path: '/background', component: () => import('../views/background.vue'), }, 
    { path: '/policy', component: () => import('../views/policy.vue'), }, 
    { path: '/psychology', component: () => import('../views/psychology.vue'), }, 
    { path: '/advice', component: () => import('../views/advice.vue'), },
    { path: '/study', component: () => import('../views/study.vue'), },
    { path: '/shop', component: () => import('../views/shop.vue'), }, 
    { path: '/digital', component: () => import('../views/digital.vue'), }, 
    { path: '/window', component: () => import('../views/window.vue'), },
    { path: '/psychologyText', component: () => import('../views/psychologyText.vue'), },
    
  ],
  },
  // { path: '/object2', component: () => import('../views/object2.vue'), }, 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
